<template>
    <div class="menuDiv">
        <!--
     mode 模式 horizontal 橫向/ vertical 直向
    :default-active 一進頁面預設顯示的頁面
    unique-opened 保持一個子選單的開啟
    router 採用路由模式 選單上的index就是點選跳轉的頁面
    text-color 選單文字的顏色
    background-color 背景色
    active-text-color 選單啟用後文字的顏色
    collapse-transition 是否開啟折疊動畫
    collapse 是否水平折疊收起菜單（僅在模式為垂直時可以）
    -->
        <el-row>
            <el-col :span="24" style="text-align:center;">
                <el-button v-model="isCollapse" v-on:click="startIsCollapse()"><i class="el-icon-menu"></i></el-button>
            </el-col>
        </el-row>

        <el-menu
                mode="vertical"
                :default-active="$route.path"
                class="el-menu-vertical-demo"
                :unique-opened="uniqueOpened"
                router
                background-color="#fff"
                text-color="#333333"
                active-text-color="#fff"
                :collapse="isCollapse"
                :collapse-transition="collapseTransition"
                @select="handleSelect"
                @open="handleOpen"
                @close="handleClose"
        >
            <MenuTree :menuData="menuData"></MenuTree>
        </el-menu>
    </div>

</template>

<!-- menu元件-->
<script>
  import MenuTree from '@/components/element/MenuTree.vue'
  //import menuJs from '@/assets/js/menu.js'

    export default {
        name: "MenuTemplate",
        components: {
            MenuTree
        },
        data() {
            return {
                isCollapse: true,
                uniqueOpened: true,
                collapseTransition: true,
                menuData: null,
                token: null,
            };
        },
        watch: {
            //監聽路由的改變
            $route() {
                this.getBreadcrumb()
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.loginReturnDto = localStorage.getItem('loginReturnDto');
                if (this.loginReturnDto != null && JSON.parse(this.loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(this.loginReturnDto);
                    this.token = this.loginReturnDto.token;
                }
                this.loadingMenu();
                this.getBreadcrumb();
            },
            async loadingMenu() {
                let tmp = this;
                let url = "login/search/getMenuData";
                let parameter = null
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.menuData = responseData;
                }
            },
            isHome(route) {
                return route.name === 'home';
            },
            getBreadcrumb() {
                let matched = this.$route.matched;
                this.$GLOBAL.breadcrumbListData = matched;
            },
            handleSelect(key, keyPath) {
                //選擇後執行
                console.log("handleSelect...........start");
                console.log("handleSelect...........key=" + key);
                console.log("handleSelect...........keyPath=" + keyPath);
            },
            handleOpen(key, keyPath) {
                //展開menu執行
                console.log("handleOpen...........start");
                console.log("handleOpen...........key=" + key);
                console.log("handleOpen...........keyPath=" + keyPath);
            },
            handleClose(key, keyPath) {
                //關閉menu執行
                console.log("handleClose...........start");
                console.log("handleClose...........key=" + key);
                console.log("handleClose...........keyPath=" + keyPath);
            },
            startIsCollapse() {
                //menu展開/縮小
                this.isCollapse = !this.isCollapse;
            }
        }
    }
</script>

<style scoped>

    .menuDiv {
        background-color: #ffffff;
        color: #333;
        text-align: left;
        line-height: 100px;
        /*min-width: 200px;*/
        /*width: auto !important;*/
    }

    [class ^= "el-icon-"] {
        /*color: #333;*/
        color: #909399;
    }

    el-menu组件上设置的class
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        min-width: 200px;
        width: auto !important;
    }

    /**縮小時隱藏箭頭**/
    .el-menu--collapse /deep/ .el-submenu__icon-arrow {
        display: none !important;
    }

    .el-menu--collapse {
        /*width: 64px;*/
        margin: 5px;
    }

    .el-menu {
        border-right: solid 0px #e6e6e6 !important;
        margin: 5px;
    }
</style>
