<template>
    <div id="SidebarDiv">
        <el-row class="tac">
            <el-col :span="22">
                <MenuTemplate></MenuTemplate>
            </el-col>
            <!--      <el-col :span="2">-->
            <!--        <el-divider direction="vertical" content-position="center"></el-divider>-->
            <!--      </el-col>-->
        </el-row>
    </div>
</template>


<script>
  import MenuTemplate from '@/components/element/MenuTemplate.vue'

  export default {
        name: "Sidebar",
        components: {
            MenuTemplate
        },
        data() {
            return {};
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
            },
        }
    }
</script>


<style>

    #SidebarDiv {
        height: 100%;
        /* Other styles can be added here */
    }

</style>

