<template>
    <div class="header">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="18">
                <div class="header-left">
                    <span class="header-title">車洗洗後台管理系統</span>
                    <span class="header-version">{{version}}</span>
                </div>
            </el-col>
            <el-col :xs="24" :sm="6">
                <div class="header-right">
                    <span class="header-nickname">{{nickName}}</span>
                    <el-button type="primary" class="logoOut" @click="logoOut">登出</el-button>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    export default {
        name: "Header",
        components: {
            // LangTemplate
        },
        data() {
            return {
                version: null,
                loginReturnDto: null,
                nickName: null,
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.version = this.$version;
                // 從 Local Storage 獲取 loginReturnDto
                const loginReturnDto = localStorage.getItem('loginReturnDto');
                if (loginReturnDto) {
                    this.loginReturnDto = JSON.parse(loginReturnDto);
                }
                console.log("Header取得loginReturnDto=" + this.loginReturnDto);
                if (this.loginReturnDto != null) {
                    this.nickName = this.loginReturnDto.nickName;
                    console.log("Home取得nickName=" + this.nickName);
                }
            },
            logoOut() {
                localStorage.setItem('loginReturnDto', null);
                localStorage.setItem('permissionControl', null);
                this.$router.push({path: '/login'});
                // const loginName = localStorage.getItem('loginName');
                // var loginNameDto;
                // if (loginName != null && JSON.parse(loginName) != null) {
                //     loginNameDto = JSON.parse(loginName);
                // }
                // if(loginNameDto == 'login'){
                //     localStorage.setItem('loginName', null);
                //     this.$router.push({path: '/login'});
                // }
                // if(loginNameDto == 'login1'){
                //     localStorage.setItem('loginName', null);
                //     this.$router.push({path: '/login1'});
                // }
            },
        }
    }
</script>

<style scoped>
    .header {
        /*background: #2554FF; !* 确保背景颜色设置正确 *!*/
        /*padding: 10px 0;*/
        position: relative; /* 确保元素是正常流中的块级元素 */
        z-index: 1; /* 设置较高的层叠顺序 */
    }

    .header-left {
        display: flex;
        align-items: center;
    }

    .header-title {
        font-size: 30px;
        margin-left: 30px;
    }

    .header-version {
        font-size: 30px;
        margin-left: 10px;
    }

    .header-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10px;
    }

    .header-nickname {
        margin-right: 10px;
    }

</style>
