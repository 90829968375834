<template>
    <div>
        <el-container class="full-height">
            <el-header>
                <Header></Header>
            </el-header>
            <el-container>
                <el-aside>
                    <Sidebar></Sidebar>
                </el-aside>
                <el-container class="main-container">
                    <el-main>
                        <Container></Container>
                    </el-main>
                </el-container>
            </el-container>
            <el-footer>
                <Footer></Footer>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
  import Header from '@/components/layout/Header.vue'
  import Sidebar from '@/components/layout/Sidebar.vue'
  import Container from '@/components/layout/Container.vue'
  import Footer from '@/components/layout/Footer.vue'

  export default {
        name: "Home",
        components: {
            Header,
            Sidebar,
            Container,
            Footer,
        },
        data() {
            return {
                menuData: null,
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
            },
        }
    }
</script>

<style>
    html, body {
        height: 100%;
        margin: 0;
    }

    .full-height {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .el-header, .el-footer {
        background-color: #015bb3;
        color: #fff;
        line-height: 60px;
    }

    .el-footer {
        text-align: center;
        flex-shrink: 0;
    }

    .el-aside {
        background-color: #ffffff;
        color: #333;
        width: auto !important;
        border-right: 1px #D3D5DD double;
    }

    .el-container {
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    .main-container {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .el-main {
        background-color: #ffffff;
        color: #333;
        padding: 0 !important;
        flex: 1;
    }
</style>
