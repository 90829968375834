<template>
  <router-view/>
</template>

<script>
  export default {
    name: "Container",
    components: {}
  }
</script>

<style scoped>

</style>



